<template>
  <div class="support">
    <div class="support-content">
      <img class="photos" v-for="(item,index) in list" :key="index" :src="item" alt="" srcset="">
    </div>
  </div>
</template>

<script>
import pic1 from "@/assets/help/1.jpg"
import pic2 from "@/assets/help/2.jpg"
import pic3 from "@/assets/help/3.jpg"
import pic5 from "@/assets/help/5.jpg"
import pic6 from "@/assets/help/6.jpg"
import pic7 from "@/assets/help/7.jpg"
import pic8 from "@/assets/help/8.jpg"
import pic9 from "@/assets/help/9.jpg"
import pic10 from "@/assets/help/10.jpg"
export default {
  data(){
    return {
      list:[
        pic1,
        pic2,
        pic3,
        pic5,
        pic6,
        pic7,
        pic8,
        pic9,
        pic10
      ]
    }
  }
};
</script>

<style lang="less">
.support-content {
    width: 1170Px;
    margin: 0 auto;
    padding-top: 30Px;
    .photos {
      width: 100%;
    }
}
</style>